'use client'

import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

import { IStaticMethods } from 'preline/preline'
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods
  }
}

export default function PrelineScript() {
  const path = usePathname()
  const [prelineLoaded, setPrelineLoaded] = useState(false)

  useEffect(() => {
    import('preline/preline')
  }, [])

  useEffect(() => {
    if (prelineLoaded) {
      // Wait a bit for the dynamic import to fully initialize and then call autoInit
      setTimeout(() => {
        window.HSStaticMethods.autoInit()
      }, 100)
    }
  }, [prelineLoaded, path])

  return null
}
