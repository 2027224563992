'use client'

import React from 'react'
import { usePathname } from 'next/navigation'

const CanonicalUrlComponent = () => {
  const pathname = usePathname()
  const canonicalUrl = `https://getcleartrade.com${pathname}`

  return <link rel="canonical" href={canonicalUrl} />
}

export default CanonicalUrlComponent
