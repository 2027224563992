import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/CanonicalRef.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/PrelineScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/context/NextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/lib/apollo-client-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/tailwind.css");
