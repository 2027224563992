'use client'

import { ApolloLink, HttpLink } from '@apollo/client'
import React from 'react'
import {
  ApolloNextAppProvider,
  SSRMultipartLink,
  InMemoryCache,
  ApolloClient,
} from '@apollo/experimental-nextjs-app-support'
import { setContext } from '@apollo/client/link/context'

// import { getToken } from "next-auth/jwt"
// import { useSession, getSession } from "next-auth/react"
import { getCookie } from 'cookies-next'

export function makeClient() {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token')

    // const data = useSession()
    // console.log("AAAAA:", session)
    // const token = data.session.accessToken
    //"eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIn0..lDKNYDIqPqDl4EoF.qLmcKfnBF7LIT0EwV53A_c225NVw38S8yiUc2WE0D4MpeAQVfvcyGyxhW3qz3CYVj2KLdzCWIFNxZYJ-bFCbzsq6P_jd23P9KttGmfOVwSg.24pWRGUy9nqfvMTvBTnpoA"

    let token = getCookie('jwt')
    // console.log('TTTOKEN:', token)
    // if (typeof window === 'undefined') {

    // }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
  })

  const finalHttpLink = authLink.concat(httpLink)

  return new ApolloClient({
    cache: new InMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([new SSRMultipartLink({ stripDefer: true }), finalHttpLink])
        : finalHttpLink,
  })
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>
}
